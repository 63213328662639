import React from 'react';
import Button from '@/components/ReusableUI/Button';
import RightArrow from '@/components/SVG/right-arrow.svg';
import DownArrow from '@/components/SVG/icon-down-arrow.svg';
import RightDelta from '@/components/SVG/right-delta.svg';

export const BasicButton = ({
    id = null,
    className = null,
    innerClassName = null,
    href = null,
    onClick = null,
    children = null,
    text = null,
    type = null,
    newTab = false,
    border = null,
    innerText = null
}) => {
    return (
        <Button id={id} className={className} href={href} onClick={onClick} type={type} newTab={newTab} border={border} aria-label={text}>
            <div className={`flex items-center justify-center ${innerClassName}`}>
                <div className={`${innerText} button-text font-Inter  `}>{text}</div>
                {children}
            </div>
        </Button>
    );
};

export const LargeButton = ({
    id = null,
    text = null,
    href = null,
    newTab = false,
    className = '',
    onClick = null,
    children = null,
    type = null,
    innerClassName = null,
    rightArrow = false,
    downArrow = false,
    rightDelta = false,
}) => {
    return (
        <BasicButton
            id={id}
            className={`
                transition-all 
                px-4 
                h-[50px] 
                ${className} 
            `}
            innerClassName={`
                font-semibold
                lg:font-semibold
                xl:font-semibold

                text-base
                lg:text-base-
                xl:text-base
                text-black
                whitespace-nowrap
                ${innerClassName}
            `}
            innerText={'text-white'}

            text={text}
            href={href}
            newTab={newTab}
            onClick={onClick}
            type={type}
        >
            {children}
            {rightArrow && <RightArrow className='button-text ml-[6px] translate-y-[1px] text-white' aria-hidden='true' />}
            {downArrow && <DownArrow className='button-text ml-[6px] translate-y-[1px]' aria-hidden='true' />}
            {rightDelta && <RightDelta className='button-text ml-[10px] translate-y-[0px]' aria-hidden='true' />}
        </BasicButton>
    );
};

export const SmallButton = ({
    id = null,
    text = null,
    href = null,
    className = '',
    children = null,
    onClick = null,
    type = null,
    rightArrow = false,
    newTab = false,
    border = ''
}) => {
    return (
        <BasicButton
            id={id}
            className={`transition-all px-4 h-[42px] ${className} `}
            innerClassName='text-sm font-semibold'
            text={text}
            href={href}
            onClick={onClick}
            type={type}
            newTab={newTab}
            border={border}
        >
            {children}
            {rightArrow && <RightArrow className='button-text ml-[6px] translate-y-[1px]' />}
        </BasicButton>
    );
};

export const NavbarButton = ({
    id = null,
    text = null,
    href = null,
    newTab = false,
    className = '',
    onClick = null,
    children = null,
    type = null,
    innerClassName = null,
    rightArrow = false,
    downArrow = false,
    rightDelta = false,

}) => {
    return (
        <BasicButton
            id={id}
            // border='2px solid #fcfcfc'
            className={`
                transition-all
                px-2
                xl:px-5
                h-[48px]
                rounded-[12px]
                ${className} 
            `}
            innerClassName={`
                font-[500]
                text-lg
                leading-snug
                tracking-tight
                whitespace-nowrap
                ${innerClassName}
            `}
            innerText={'text-black text-xl'}
            text={text}
            href={href}
            newTab={newTab}
            onClick={onClick}
            type={type}
        >
            {children}
            {rightArrow && <RightArrow className='button-text ml-[6px] translate-y-[1px]' />}
            {downArrow && <DownArrow className='button-text ml-[6px] translate-y-[1px]' />}
            {rightDelta && <RightDelta className='button-text ml-[10px] translate-y-[0px]' />}
        </BasicButton>
    );
};

export const LoginButton = ({
    id = null,
    text = null,
    href = null,
    newTab = false,
    className = '',
    onClick = null,
    children = null,
    type = null,
    innerClassName = null,
    rightArrow = false,
    downArrow = false,
    rightDelta = false
}) => {
    return (
        <BasicButton
            id={id}
            className={`
                transition-all
                px-2
                xl:px-4 
                
                h-[40px]
                xl:h-[50px]
                rounded-[18px]
                ${className} 
            `}
            innerClassName={`
                font-extrabold
                text-sm
                leading-snug
                tracking-tight
                whitespace-nowrap
                ${innerClassName}
            `}
            text={text}
            href={href}
            newTab={newTab}
            onClick={onClick}
            type={type}
        >
            {children}
            {rightArrow && <RightArrow className='button-text ml-[6px] translate-y-[1px]' />}
            {downArrow && <DownArrow className='button-text ml-[6px] translate-y-[1px]' />}
            {rightDelta && <RightDelta className='button-text ml-[10px] translate-y-[0px]' />}
        </BasicButton>
    );
};

export default LargeButton;
