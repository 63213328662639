// get the redirection from a response
export const getResponseRedirection = (response) => {
    if (response && [301, 302, 303].includes(response.status)) {
        // 302 redirect from the header
        return response.headers.location;
    } else if (response.status === 200 && response.data?.redirect) {
        // 200 redirect from the payload
        return response.data.redirect;
    }
    return null;
};

// handle the redirection from a response
export const handleRedirection = (response) => {
    const redirect = getResponseRedirection(response);
    if (redirect) {
        window.location.href = redirect;
        return true;
    }
    return false;
};

