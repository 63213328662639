import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js/pure';
import { FREE_EMAIL_PROVIDERS } from './free_email_providers';
import { MUZOLOGY_API, MUZOLOGY_API_TOKEN, MUZOLOGY_APP, STRIPE_API_KEY, WEBSITE_URL } from './defaults';
import { handleRedirection } from './redirect';

// setup axios
axios.defaults.baseURL = MUZOLOGY_API;

// axios.interceptors.response.use(
//     response => response,
//     error => {
//         if (error.response && [301, 302, 303].includes(error.response.status)) {
//             const redirectUrl = error.response.headers.location;
//             console.log('Redirecting to:', redirectUrl);
//             return axios.get(redirectUrl);
//         }
//         return Promise.reject(error);
//     }
// );

// important URLs
const PARENT_SIGNUP_PATH = '/signup?role=parent';

// server API endpoints
const TEACHER_TRIAL_SIGNUP_PATH = '/api/signup/teacher-trial/'; // (v2) teacher signup
const TANOSHI_TRIAL_SIGNUP_PATH = '/api/signup/tanoshi-trial/'; // (v2) tanoshi signup

const STRIPE_PURCHASE_API = '/api/stripe_purchase/'; // (v1) purchase a plan for a new account
const STRIPE_CHECKOUT_API = '/api/stripe/checkout/'; // (v2) purchase a plan for a new account

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const submitTeacherSignup = (formData: any, redirect = true) => {
    // console.log('submit_teacher_signup', formData);
    // const url = 'https://app.muzology.com/api/educator_program/';
    // console.log('axios default base url:', axios.defaults.baseURL);
    return axios.post(TEACHER_TRIAL_SIGNUP_PATH, formData)
        .then((response) => {
            if (redirect) {
                console.log('submit_teacher_signup response', response);
                handleRedirection(response);
            }
            // const { data } = response;
            // console.log('signup success', data);
            // const { redirect, success } = data;
            // if (success && redirect) {
            //     window.location.href = redirect;
            // }
            return response;
        })
        .catch((error) => {
            console.log('signup error', error);
            console.dir(error.response);
            const { response } = error;
            if (response) {
                const { data } = response;
                if (data) {
                    const { errors } = data;
                    console.log('signup error', errors);
                }
            }
            throw error;
        });

    /*
        return axios.post(TEACHER_TRIAL_SIGNUP_PATH, formData).then(({ data }) => {
            console.log('signup success', data);
            const { redirect, success } = data;
            if (success && redirect) {
                window.location.href = redirect;
            }
        });
     */
};

export const submitTanoshiSignup = (formData: any) => {
    console.log('submit_tanoshi_signup', formData);
    return axios.post(TANOSHI_TRIAL_SIGNUP_PATH, formData).then(({ data }) => {
        console.log('signup success', data);
        const { redirect, success } = data;
        if (success && redirect) {
            window.location.href = redirect;
        }
    });
};

// purchase a subscription via stripe
export const submitParentPurchase = (formData: any, metaData: any = null, cancelUrl: string = null) => {
    /*
        purchase a subscription
        formData:
            // name
            // email
            // password
            plan
            cancel_url
            metadata
            allow_coupons (optional)
     */
    console.log('submitParentPurchase', formData, metaData, cancelUrl);

    const payload = {
        // name: formData.name,
        // email: formData.email,
        // password: formData.password,
        plan: formData.plan, // eslint-disable-next-line camelcase
        allow_coupons: formData.allowCoupons ?? false, // eslint-disable-next-line camelcase
        cancel_url: cancelUrl ?? `${WEBSITE_URL}${PARENT_SIGNUP_PATH}`, metadata: metaData ?? {}
    };
    return axios
        .post(STRIPE_CHECKOUT_API, payload)
        .then(async (response) => {
            // console.log('purchase success', response.data);
            const { id, redirect } = response.data;
            if (id) {
                if (!STRIPE_API_KEY) {
                    console.error('Invalid STRIPE_API_KEY', STRIPE_API_KEY);
                }
                // load stripe & redirect to checkout
                const stripe = await loadStripe(STRIPE_API_KEY);
                return stripe.redirectToCheckout({ sessionId: id });
                // loadStripe(STRIPE_API_KEY)
                //     .then(stripe => stripe.redirectToCheckout({ sessionId: id }));
            } else if (redirect) {
                // redirect to a url
                window.location.href = redirect;
            } else {
                // unknown response...
                console.error('[Stripe Checkout] Unknown response:', response.data);
            }
            return response.data;
        })
        .catch((err) => {
            console.log('purchase error', err);
            console.log(err);
            throw err;
        });
};

// export const submit_account_purchase = (accountId: string, plan: string, metaData: any = null, cancelUrl: string = null) => {
//     console.log('submit_parent_purchase', accountId, plan);
//
//     const payload = {
//         account_id: accountId,
//         plan: plan,
//         cancel_url: cancelUrl ?? `${WEBSITE_URL}${PARENT_SIGNUP_PATH}`,
//         meta_data: metaData ?? {}
//     };
//
//     return axios
//         .post(`${MUZOLOGY_API}${STRIPE_PURCHASE_API}`, qs.stringify(payload), {
//             headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
//         })
//         .then(async (response) => {
//             // console.log('purchase success', response.data);
//             const { id, redirect } = response.data;
//             if (id) {
//                 if (!STRIPE_API_KEY) {
//                     console.error('Invalid STRIPE_API_KEY', STRIPE_API_KEY);
//                 }
//                 // load stripe & redirect to checkout
//                 const stripe = await loadStripe(STRIPE_API_KEY);
//                 return stripe.redirectToCheckout({ sessionId: id });
//                 // loadStripe(STRIPE_API_KEY).then((stripe) => stripe.redirectToCheckout({ sessionId: id }));
//             } else if (redirect) {
//                 // redirect to a url
//                 window.location.href = redirect;
//             } else {
//                 // unknown response...
//                 console.error('[Stripe Checkout] Unknown response:', response.data);
//             }
//             return response.data;
//         })
//         .catch((err) => {
//             console.log('purchase error', err);
//             console.log(err);
//             throw err;
//         });
// };

export const checkValidEmail = (email) => {
    const valid = email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    console.log(email, valid);
    return valid !== null;
};

export function domainFromEmail(email) {
    return email?.split('@')[1];
}

export function checkValidEmailDomain(email) {
    if (!email)
        return false;
    const domain = domainFromEmail(email);
    if (!domain)
        return false;
    // invalidate domains that contain the word 'student'
    if (domain?.includes('student')) return false;
    return Object.prototype.hasOwnProperty.call(FREE_EMAIL_PROVIDERS, domain) ? FREE_EMAIL_PROVIDERS[domain] : true;
}

// begin joining via a join code
export const openJoinPage = (joinCode: string) => {
    window.location.href = `${MUZOLOGY_APP}/join?join_code=${joinCode}`;
};

interface SignupForm {
    email?: string;
    token?: string;
    password?: string;
    name?: string;
}

// begin the onboard process
export const postOnboardSetup = async (data: SignupForm) => {
    return axios
        .post('/api/onboard/signup/', data, { withCredentials: true })
        .then(async (res) => {
            console.log('success', res.data);
            // manually redirect
            window.location.href = MUZOLOGY_APP;
            // window.location.href = 'https://app.muzology.com/'
            // manually redirect on 302
            // if (res.status === 302) {
            //     window.location.href = res.headers['location'];
            // }
            // return the promise
            // continue with the promise
            return res;
        })
        .catch((e: any) => {
            console.log(e);
            return e;
        });
};

export const videoCatalog = async () => {
    // console.log('submit_teacher_signup', formData);
    // const url = 'https://app.muzology.com/api/educator_program/';
    // console.log('axios default base url:', axios.defaults.baseURL);
    return [];
    // return axios.get('/api/videos/', {
    //     withCredentials: true,
    //     headers: { 'Authorization': 'Token ' + MUZOLOGY_API_TOKEN }
    // }).then((response) => {
    //     console.log('video_catalog response', response);
    //     const catalog = response.data;
    //     console.log('catalog', catalog);
    //     return catalog;
    // }).catch((error) => {
    //     console.log('video_catalog error', error);
    //     throw error;
    // });
};
