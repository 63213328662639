import React from 'react';

interface Props {
    id?: string;
    children: string | object;
    href?: string;
    newTab?: boolean; // if true, opens link in new tab
    className?: string;
    border?: string;

    onClick?: any;

    type?: any;
}

export const openURL = (url, newTab = false) => {
    window.open(url, newTab ? '_blank' : '_self');
};

export default function Button({ id = null, children, href, className, border, newTab = false, onClick = null, ...props }: Props) {
    let transparentBorder = border;
    if (!border) {
        transparentBorder = 'transparent';
    }

    function handleOnClick(event) {
        if (href) {
            event.preventDefault();
            openURL(href, newTab);
            // window.location.href = href;
        } else if (onClick) {
            onClick(event);
        }
    }

    return (
        <button
            // ref={ref}
            id={id}
            className={`font-Inter select-none px-4 py-2 border rounded-md font-light text-xs text-white ${className}`}
            style={{ borderColor: transparentBorder }}
            onClick={handleOnClick}
            {...props}
        >
            <>{children}</>
        </button>
    );
    // const target = newTab ? '_blank' : '_self';
    // if (href) {
    //     return (
    //         <Link href={href} passHref target={target}>
    //             <a target={target} rel={newTab ? 'noopener noreferrer' : ''}>
    //                 {button}
    //             </a>
    //         </Link>
    //     );
    // }
    // return button;
}
